import { GlobalElementsConfig, LineOfBusinessConfig, PageBrand } from '../types'

const geConfig: GlobalElementsConfig = {
  header: 'business',
  footer: 'business',
}

const pageBrand: PageBrand = 'corporate'

export const business = new LineOfBusinessConfig(geConfig, pageBrand)
