import { GlobalElementsConfig, LineOfBusinessConfig, PageBrand } from '../types'

const geConfig: GlobalElementsConfig = {
  header: 'partner',
  footer: 'partner',
}

const pageBrand: PageBrand = 'corporate'

export const partner = new LineOfBusinessConfig(geConfig, pageBrand)
