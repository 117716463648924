import { GlobalElementsConfig, LineOfBusinessConfig, PageBrand } from '../types'

const geConfig: GlobalElementsConfig = {
  header: 'ventures-header',
  footer: 'ventures-footer',
}

const pageBrand: PageBrand = 'corporate'

export const ventures = new LineOfBusinessConfig(geConfig, pageBrand)
