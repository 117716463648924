import { LANGUAGES, PROVINCES } from './constants'

export const removeLocaleFromSlug = (slug: string | string[]) => {
  let finalSlug: string

  let slugs: string[]

  if (Array.isArray(slug)) {
    slugs = [...slug]
  } else if (slug) {
    slug = slug.replace(/^\/+/g, '')
    slugs = slug.split('/')
  } else {
    // Handle root slug
    slugs = ['/']
  }

  const slugHasLanguage = LANGUAGES.includes(slugs[0])
  if (slugHasLanguage) {
    slugs.shift()
  }
  const provinceHasLanguage = PROVINCES.includes(slugs[0])
  if (provinceHasLanguage) {
    slugs.shift()
  }

  if (slugs.length === 0) {
    finalSlug = ''
  } else {
    finalSlug = `${slugs.join('/')}`
  }
  return finalSlug
}
