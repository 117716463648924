const generateStructuredData = (structuredData) => {
  const structuredDataType = structuredData?.sys.contentType.sys.id

  switch (structuredDataType) {
    case 'structuredDataJson':
      return structuredData?.fields.data
    default:
      return undefined
  }
}

export default generateStructuredData
