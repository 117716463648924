import { GlobalElementsConfig, LineOfBusinessConfig, PageBrand } from '../types'

const geConfig: GlobalElementsConfig = {
  header: 'personal-dev',
  footer: 'personal',
}

const pageBrand: PageBrand = 'consumer'

export const devTest = new LineOfBusinessConfig(geConfig, pageBrand)
