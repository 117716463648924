import { GlobalElementsConfig, LineOfBusinessConfig, PageBrand } from '../types'

const geConfig: GlobalElementsConfig = {
  header: 'pollinator-header',
  footer: 'pollinator-footer',
}

const pageBrand: PageBrand = 'corporate'

export const pollinatorFund = new LineOfBusinessConfig(geConfig, pageBrand)
