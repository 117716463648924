import { GlobalElementsConfig, LineOfBusinessConfig, PageBrand } from '../types'

const geConfig: GlobalElementsConfig = {
  header: 'social-impact',
  footer: 'about',
}

const pageBrand: PageBrand = 'corporate'

export const socialImpact = new LineOfBusinessConfig(geConfig, pageBrand)
