import { GlobalElementsConfig, LineOfBusinessConfig, PageBrand } from '../types'

const geConfig: GlobalElementsConfig = {
  header: 'foundation',
  footer: 'friendly-future-foundation-footer-navigation',
}

const pageBrand: PageBrand = 'corporate'

export const futureFriendlyFoundation = new LineOfBusinessConfig(geConfig, pageBrand)
