import { CreateClientParams, ContentfulClientApi } from 'contentful'
import { NextApiRequestCookies } from 'next/dist/server/api-utils'
import sitebuilderConfig from '../../../sitebuilder.config'

export declare type OptionalSpaceOptions = Partial<
  Omit<CreateClientParams, 'accessToken' | 'space' | 'environment'>
> & {
  client?: ContentfulClientApi
  slugCacheClient?: ContentfulClientApi
}

export interface SpaceOptions {
  getClient: (
    cookies: NextApiRequestCookies,
    transactiondId: string,
    forceContentfulFallbackCache: string
  ) => ContentfulClientApi
  options?: OptionalSpaceOptions
}

export type LineOfBusinessSpace = SpaceOptions & {
  label: string
  lineOfBusiness?: string
}

export type GlobalElementsConfig = {
  header: string
  footer: string
}

export type PageBrand = 'consumer' | 'corporate' | 'health'

export type AnalyticsScript = {
  tag?: 'script' | 'noscript'
  src?: string
  defer?: boolean
  id?: string
  innerHTML?: string
  destinationTag?: 'head' | 'body'
  insertType?: 'append' | 'prepend'
}

const DEFAULT_ANALYTICS_SCRIPT: AnalyticsScript = {
  src: sitebuilderConfig.TAG_MANAGER_URL as string,
  defer: true,
}

export class LineOfBusinessConfig {
  public geConfig: GlobalElementsConfig
  public pageBrand: PageBrand
  public analyticsScripts?: AnalyticsScript[] = []

  constructor(geConfig: GlobalElementsConfig, pageBrand: PageBrand, analyticsScripts?: AnalyticsScript[]) {
    this.geConfig = geConfig
    this.pageBrand = pageBrand
    this.analyticsScripts = [DEFAULT_ANALYTICS_SCRIPT]

    if (analyticsScripts && analyticsScripts.length > 0) {
      this.analyticsScripts = [...this.analyticsScripts, ...analyticsScripts]
    }
  }
}
