import { GlobalElementsConfig, LineOfBusinessConfig, PageBrand } from '../types'

const geConfig: GlobalElementsConfig = {
  header: 'digital',
  footer: 'digital',
}

const pageBrand: PageBrand = 'corporate'

export const digital = new LineOfBusinessConfig(geConfig, pageBrand)
