import { LANGUAGES } from '../locale'

import { annualReports } from './configs/annualReports'
import { annualReports18 } from './configs/annualReports18'
import { brandResources } from './configs/brandResources'
import { business } from './configs/business'
import { careCentres } from './configs/careCentres'
import { consumer } from './configs/consumer'
import { corporate } from './configs/corporate'
import { devTest } from './configs/devTest'
import { digital } from './configs/digital'
import { employerSolutions } from './configs/employerSolutions'
import { futureFriendly } from './configs/futureFriendly'
import { futureFriendlyFoundation } from './configs/futureFriendlyFoundation'
import { health } from './configs/health'
import { holidayDelighter } from './configs/holidayDelighter'
import { inAppHeaderFooter } from './configs/inAppHeaderFooter'
import { internal } from './configs/internal'
import { mediumLargeBusiness } from './configs/mediumLargeBusiness'
import { partner } from './configs/partner'
import { partnerSolutions } from './configs/partnerSolutions'
import { pollinatorFund } from './configs/polllinatorFund'
import { simpleHeaderFooter } from './configs/simpleHeaderFooter'
import { smallBusiness } from './configs/smallBusiness'
import { socialImpact } from './configs/socialImpact'
import { ventures } from './configs/ventures'
import { whyTelus } from './configs/whyTelus'
import { wise } from './configs/wise'

import { LineOfBusinessConfig } from './types'

// The object below contains the different site-builder tenants and their spaces
// Keys represent the root path slugs (e.g. /about, /internet, /mobility etc.)
// Values are one of the header/footer/contentful configurations imported above
export const lineOfBusiness: Record<string, LineOfBusinessConfig> = {
  default: consumer,
  qa: corporate, // This root path is used by the E2E tests
  internet: consumer,
  mobility: consumer,
  tv: consumer,
  'home-security': consumer,
  about: corporate,
  'about/investor-relations/reports/annual-reports/2017': annualReports,
  'about/company-overview/futurefriendly': futureFriendly,
  'about/investor-relations/reports/annual-reports/2018': annualReports18,
  'about/investor-relations/reports/annual-reports/2018/ceo-letter': annualReports18,
  digital,
  'digital/cse-le-program': internal,
  digitaloutage: consumer,
  health,
  'health/care-centres': careCentres,
  'employer-solutions': employerSolutions,
  ventures: ventures,
  pollinatorfund: pollinatorFund,
  futurefriendly: futureFriendly,
  wise: wise,
  'brand-resources': brandResources,
  foundation: futureFriendlyFoundation,
  'business/small': smallBusiness,
  'business/v2/small': smallBusiness,
  'business/medium-large': mediumLargeBusiness,
  'business/v2/medium-large': mediumLargeBusiness,
  business,
  unsubscribe: consumer,
  'partner-solutions': partnerSolutions,
  partner: partner,
  jade: corporate,
  'why-telus': whyTelus,
  'sandbox/consumer': consumer,
  'sandbox/corporate': digital,
  'deals-and-bundles/employee-purchase-program-sign-up': simpleHeaderFooter,
  'deals-and-bundles/partner-program': simpleHeaderFooter,
  'sitebuilder-demo': consumer,
  'social-impact': socialImpact,
  'blog/social-impact': socialImpact,
  'blog/indigenous-connectivity': socialImpact,
  'blog/health': health,
  'blog/digital': digital,
  'blog/pollinatorfund': pollinatorFund,
  'blog/foundation': futureFriendlyFoundation,
  'blog/sustainability-sharing-our-progress': corporate,
  'blog/real-estate': corporate,
  'blog/business': business,
  'blog/standwithowners': smallBusiness,
  'blog/partner': partnerSolutions,
  'social-impact/calendar/december2022': holidayDelighter,
  'sitebuilder-demo/social-impact': socialImpact, // temp lineOfBusiness to test with the right header/footer
  'sitebuilder-demo/global-elements-testing': devTest,
  inApp: inAppHeaderFooter,
}

// strips leading and trailing /'s
const trimPath = (path: string) => path.replace(/^\/*|\/*$/g, '')
const removeLang = (slug: string): string => {
  const split: string[] = slug.split('/')

  if (LANGUAGES.includes(split[0])) {
    split.splice(0, 1)
  }
  return split.join('/')
}

const getContextPath = (path: string): string => {
  const trimmedPath = removeLang(trimPath(path))
  const orderedContextPaths = Object.keys(lineOfBusiness).sort().reverse()
  return orderedContextPaths.find((key) => trimmedPath.startsWith(key))
}

export function getLineOfBusiness(path: string): LineOfBusinessConfig {
  const contextPath = getContextPath(path)
  return lineOfBusiness[contextPath] || lineOfBusiness.default
}
