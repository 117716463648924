import { ISeoTagsFields, IStructuredDataJson } from 'contentful-types'
import NextHead from 'next/head'
import { sanitize } from 'isomorphic-dompurify'

import generateStructuredData from './StructuredDataFactory'

import { getSEOMetadata } from './SEO/metadata'
import { Language } from '../../locale'

export interface HeadProps {
  title: string
  seo?: ISeoTagsFields
  language?: Language
  structuredData?: IStructuredDataJson
  slug?: string
}

export const Head = ({ seo, structuredData, slug, language, title }: HeadProps) => {
  const metaData = getSEOMetadata(seo, language, slug, title)

  return (
    <NextHead>
      <title>{title}</title>
      {metaData.meta.map((meta, index) => (
        <meta key={`m-${index}`} name={meta.name} property={meta.property} content={meta.content} />
      ))}
      {metaData.link.map((link, index) => (
        <link key={`l-${index}`} href={link.href} rel={link.rel} />
      ))}
      {structuredData && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: sanitize(JSON.stringify(generateStructuredData(structuredData))),
          }}
        />
      )}
    </NextHead>
  )
}

export default Head
