import { GlobalElementsConfig, LineOfBusinessConfig, PageBrand } from '../types'

const geConfig: GlobalElementsConfig = {
  header: 'annualreports',
  footer: 'annualreports',
}

const pageBrand: PageBrand = 'corporate'

export const annualReports = new LineOfBusinessConfig(geConfig, pageBrand)
