import { GlobalElementsConfig, LineOfBusinessConfig, PageBrand } from '../types'

const geConfig: GlobalElementsConfig = {
  header: 'about',
  footer: 'annualreports',
}

const pageBrand: PageBrand = 'corporate'

export const annualReports18 = new LineOfBusinessConfig(geConfig, pageBrand)
