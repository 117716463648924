export const ENGLISH = 'en'
export const FRENCH = 'fr'

export const PROVINCES = ['ab', 'bc', 'mb', 'nb', 'nl', 'ns', 'nt', 'nu', 'on', 'pe', 'qc', 'sk', 'yt']
export const DEFAULT_PROVINCE = 'bc'

export const LANGUAGES = [ENGLISH, FRENCH]
export const DEFAULT_LANGUAGE = ENGLISH

export const countries = ['CA']
export const DEFAULT_COUNTRY = 'CA'
