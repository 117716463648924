import { AnalyticsScript, GlobalElementsConfig, LineOfBusinessConfig, PageBrand } from '../types'

const geConfig: GlobalElementsConfig = {
  header: 'care-centres',
  footer: 'care-centres',
}

const pageBrand: PageBrand = 'corporate'

const analyticsScripts: AnalyticsScript[] = [
  {
    tag: 'script',
    id: 'google-tag-manager-script',
    defer: true,
    insertType: 'prepend',
    destinationTag: 'head',
    innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','gtmDataLayer','GTM-MV5RJNJ');`,
  },
  {
    tag: 'noscript',
    id: 'google-tag-manager-noscript',
    defer: true,
    insertType: 'prepend',
    destinationTag: 'body',
    innerHTML: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MV5RJNJ" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
  },
]

export const careCentres = new LineOfBusinessConfig(geConfig, pageBrand, analyticsScripts)
