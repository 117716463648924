import { GlobalElementsConfig, LineOfBusinessConfig, PageBrand } from '../types'

const geConfig: GlobalElementsConfig = {
  header: 'simple-header',
  footer: 'simple-footer',
}

const pageBrand: PageBrand = 'corporate'

export const internal = new LineOfBusinessConfig(geConfig, pageBrand)
