import { GlobalElementsConfig, LineOfBusinessConfig, PageBrand } from '../types'

const geConfig: GlobalElementsConfig = {
  header: 'brand-resources',
  footer: 'brand-resources',
}

const pageBrand: PageBrand = 'corporate'

export const brandResources = new LineOfBusinessConfig(geConfig, pageBrand)
