import { GlobalElementsConfig, LineOfBusinessConfig, PageBrand } from '../types'

const geConfig: GlobalElementsConfig = {
  header: 'employer-solutions',
  footer: 'employer-solutions',
}

const pageBrand: PageBrand = 'corporate'

export const employerSolutions = new LineOfBusinessConfig(geConfig, pageBrand)
